import { acceptHMRUpdate, defineStore } from 'pinia'
import { removeCookie } from 'typescript-cookie'
import { permissions } from '~/composables/permissions'
import type { Registration } from '~/models/Auth'
import { User } from '~/models/Auth'
import type { UserUpdateForm } from '~/models/User'
import AuthService from '~/services/auth'
import CustomerManagersService from '~/services/customerManagers'
import SiteManagersService from '~/services/siteManagers'
import UsersService from '~/services/users'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: new User(),
  }),

  getters: {
    isAuthenticated(state) {
      return !!state.user.id
    },

    getUserLanguage(state) {
      return state.user.language
    },

    hasRole: (state) => {
      return (role: string) => role.includes(state.user.role)
    },

    getRole: (state) => {
      return state.user.role
    },

    getFullName: (state) => {
      return `${state.user.firstname} ${state.user.lastname}`
    },

    getLastLoggedIn: (state) => {
      return `${state.user.last_logged_in} ${state.user.last_logged_in}`
    },

    hasPermission: state => (permission: string) => {
      return permissions[permission as keyof typeof permissions].includes(state.user.role)
    },
  },

  actions: {
    async login(email: string, password: string) {
      const tokens = await AuthService.login(email, password)
      AuthService.saveTokens(tokens)
    },

    async resetPasswordRequest(email: string) {
      return AuthService.resetPasswordRequest(email)
    },

    async setPassword(token: string, password: string) {
      return AuthService.setPassword(token, password)
    },

    async resetPassword(token: string, password: string) {
      return AuthService.resetPassword(token, password)
    },

    async register(registration: Registration) {
      return AuthService.register(registration)
    },

    async logout() {
      removeCookie('access_token', { path: '/', secure: location.protocol === 'https:', sameSite: 'strict' })
      removeCookie('refresh_token', { path: '/', secure: location.protocol === 'https:', sameSite: 'strict' })
      this.user = new User()
    },

    async getUser() {
      const { user } = await AuthService.getUser()
      this.user = new User(user)
    },

    async updateUser(user: UserUpdateForm) {
      switch (this.user.role) {
        case 'flovea_manager':
          AuthService.saveTokens(await UsersService.updateUser(user))
          break
        case 'customer_manager':
          AuthService.saveTokens(await CustomerManagersService.updateCustomerManager(this.user.customer_id, this.user.id, user))
          break
        case 'site_manager':
          AuthService.saveTokens(await SiteManagersService.updateSiteManager(this.user.customer_id, this.user.id, user))
          break
      }
    },

    async updatePassword(password: string, currentPassword: string) {
      await AuthService.updatePassword(password, currentPassword)
    },
  },

})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
